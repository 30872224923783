import { useCallback, useEffect, useRef } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useStatefulObservableEffect } from '@atlassian/rx-hooks';

import { type AIAgentStreamingRequest } from '../api/AssistantService/AIAgentStreamingModels';
import { aiAgentStreamingEffect } from '../effects/aiAgentStreamingEffect';
import { type UserAIAgentStreamingConfig } from '../models/AIAgentStreamingConfig';
import {
	type AIAgentStreamingState,
	getDefaultAIAgentStreamingState,
} from '../models/AIAgentStreamingState';

export function useAIAgentStreaming<
	TAIAgentStreamingRequest extends AIAgentStreamingRequest,
	TAIAgentContentResponse,
>(
	request: TAIAgentStreamingRequest,
	config: UserAIAgentStreamingConfig,
	onStateChange?: (state: AIAgentStreamingState<TAIAgentContentResponse>) => void,
): [AIAgentStreamingState<TAIAgentContentResponse>, () => void] {
	const refetchId = useRef<string>(uuidv4());

	const [state, setState] = useStatefulObservableEffect<
		AIAgentStreamingState<TAIAgentContentResponse>,
		[TAIAgentStreamingRequest, UserAIAgentStreamingConfig, string]
	>(aiAgentStreamingEffect, getDefaultAIAgentStreamingState(), [
		request,
		config,
		refetchId.current,
	]);

	useEffect(() => {
		onStateChange?.(state);
	}, [state, onStateChange]);

	const refetch = useCallback(() => {
		refetchId.current = uuidv4();
		setState({
			inputs: [request, config, refetchId.current],
			state: getDefaultAIAgentStreamingState(),
		});
	}, [request, config, setState]);

	return [state, refetch];
}
