import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Form from '@atlaskit/form';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';

import { fg } from '@confluence/feature-gating';

import {
	useAiBulkNavigationCurrentPage,
	useAiBulkNavigationGotoPage,
} from './providers/AiBulkNavigationContextProvider';
import { BulkConfigureFooter } from './BulkConfigureFooter';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { BulkCreateNavigationHeader } from './BulkCreateNavigationHeader';
import { messages } from './messages';
import { SiteSelector } from './SiteSelector';
import { ProjectSelector } from './ProjectSelector';
import { DefaultIssueTypeSelector } from './DefaultIssueTypeSelector';
import { SidePanelWrapper } from './SidePanelWrapper';
import {
	useAvailableSitesState,
	useIsConfigureLoading,
	useIssueTypesState,
	useMappingsActions,
	useProjectsActions,
	useProjectsState,
	useResetConfigureState,
} from './providers/BulkConfigureContextProvider';
import { saveJiraConfigDetailsToLocalStorage } from './utils/localStorageHelpers';
import { ColumnToIssueFieldMappingView } from './ColumnToIssueFieldMappingView';

export type MappingViewRefType = {
	validateMappings: () => boolean;
};

const bulkConfigureViewStyles = xcss({
	flexGrow: 1,
	overflowY: 'auto',
});

// This is the view for both AI and non-AI bulk create where users can edit and create individual issues
export const BulkConfigureView = () => {
	const [
		{ isCreatedWithAi, currentJiraSite, currentJiraProject, currentDefaultIssueType },
		{ setCurrentJiraSite, setCurrentJiraProject, setCurrentDefaultIssueType },
	] = useBulkCreateContext();

	const { selectedAvailableSite } = useAvailableSitesState();
	const { selectedProject } = useProjectsState();
	const { selectedIssueType } = useIssueTypesState();
	const { isLoading, isJiraError } = useIsConfigureLoading();
	const resetConfigureState = useResetConfigureState();
	const { setIsJiraError } = useProjectsActions();
	const {
		setUnsavedColumnToFieldMappings,
		initUnsavedColumnToFieldMappings,
		saveUnsavedColumnToFieldMapping,
	} = useMappingsActions();

	const goToPage = useAiBulkNavigationGotoPage();
	const currentPage = useAiBulkNavigationCurrentPage();

	const isOpen = currentPage === 'configure';

	const mappingViewRef = useRef<MappingViewRefType | null>(null);

	const onSubmit = useCallback(() => {
		if (!isCreatedWithAi) {
			if (mappingViewRef.current?.validateMappings()) {
				saveUnsavedColumnToFieldMapping();
			} else {
				return;
			}
		}
		selectedAvailableSite && void setCurrentJiraSite(selectedAvailableSite);
		selectedProject && void setCurrentJiraProject(selectedProject);
		selectedIssueType && void setCurrentDefaultIssueType(selectedIssueType);
		saveJiraConfigDetailsToLocalStorage({
			selectedSite: selectedAvailableSite,
			selectedProject,
			selectedIssueType,
		});
		void goToPage('default');
	}, [
		selectedAvailableSite,
		setCurrentJiraSite,
		selectedProject,
		setCurrentJiraProject,
		selectedIssueType,
		setCurrentDefaultIssueType,
		isCreatedWithAi,
		saveUnsavedColumnToFieldMapping,
		goToPage,
	]);

	const onClose = useCallback(() => {
		if (currentJiraSite && currentJiraProject && currentDefaultIssueType) {
			void resetConfigureState(currentJiraSite, currentJiraProject, currentDefaultIssueType);
		}
		if (!isCreatedWithAi) {
			setUnsavedColumnToFieldMappings(null);
			initUnsavedColumnToFieldMappings();
		}
		void setIsJiraError(false);
		void goToPage('default');
	}, [
		currentDefaultIssueType,
		currentJiraProject,
		currentJiraSite,
		goToPage,
		resetConfigureState,
		setIsJiraError,
		isCreatedWithAi,
		setUnsavedColumnToFieldMappings,
		initUnsavedColumnToFieldMappings,
	]);

	const isSaveDisabled =
		isLoading || !selectedAvailableSite || !selectedProject || !selectedIssueType;
	const bulkCreateConfigureBodyText = fg('confluence-issue-terminology-refresh')
		? messages.bulkCreateConfigureBodyTextIssueTermRefresh
		: messages.bulkCreateConfigureBodyText;
	return (
		<SidePanelWrapper isOpen={isOpen}>
			<Box padding="space.200" xcss={bulkConfigureViewStyles}>
				<Stack space="space.100">
					<BulkCreateNavigationHeader onClose={onClose}>
						<FormattedMessage {...messages.bulkConfigureHeaderText} />
					</BulkCreateNavigationHeader>
					<Text color="color.text.subtlest">
						<FormattedMessage {...bulkCreateConfigureBodyText} />
					</Text>
					{isJiraError && (
						<SectionMessage appearance="error" testId="bulkCreateErrorSectionMessage">
							<FormattedMessage {...messages.bulkConfigureJiraErrorText} />
						</SectionMessage>
					)}
					<Stack space="space.300">
						<Form onSubmit={onSubmit}>
							{({ formProps }) => (
								<form id="configureForm" {...formProps}>
									<SiteSelector />
									<ProjectSelector />
									<DefaultIssueTypeSelector />
								</form>
							)}
						</Form>
						{!isCreatedWithAi && <ColumnToIssueFieldMappingView ref={mappingViewRef} />}
					</Stack>
				</Stack>
			</Box>
			<BulkConfigureFooter onClose={onClose} isSaveDisabled={isSaveDisabled} />
		</SidePanelWrapper>
	);
};
