import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';

import type { IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';

import { AiSubFooter } from './AiSubFooter';
import { BulkCreateNavigationHeader } from './BulkCreateNavigationHeader';
import {
	useAiBulkNavigationCurrentPage,
	useAiBulkNavigationGotoPage,
} from './providers/AiBulkNavigationContextProvider';
import { GicPanelBody } from './GicPanelBody';
import {
	useBulkCreateContext,
	useGetIssueType,
	useSetNextIssueToEdit,
	useSetSingleIssuesCreated,
} from './providers/BulkCreateContextProvider';
import { saveCloudIdToLocalStorage } from './utils/localStorageHelpers';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { SidePanelWrapper } from './SidePanelWrapper';
import { getSource, useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';
import { messages } from './messages';
import { mapGICPayloadToCreateIssueResponse } from './utils/apiUtils';

type BulkEditIssueViewProps = {
	isAiIssue?: boolean;
};

const bulkEditContentWrapper = xcss({
	flexGrow: '1',
});

// This is the view for both AI and non-AI bulk create where users can edit and create individual issues
export const BulkEditIssueView = ({ isAiIssue = false }: BulkEditIssueViewProps) => {
	const goToPage = useAiBulkNavigationGotoPage();
	const setSingleIssuesCreated = useSetSingleIssuesCreated();
	const setNextIssueToEdit = useSetNextIssueToEdit();
	const currentPage = useAiBulkNavigationCurrentPage();
	const { logSingleIssueCreateSuccess, logSingleIssueCreateFailure, logCreateSingleIssueClick } =
		useBulkCreateLoggingEvents();

	const [
		{
			currentIssueId,
			currentJiraProject,
			currentJiraSite,
			bulkIssuesList,
			isCreatedWithAi,
			isCreatedFromTable,
		},
	] = useBulkCreateContext();
	const issueType = useGetIssueType();
	const currentIssue = bulkIssuesList.find((issue) => issue.id === currentIssueId);
	const { triggerSingleIssueCreatedFlag } = useBulkCreateFlags();

	const onDismiss = useCallback(() => {
		void goToPage('default');
	}, [goToPage]);

	const onClose = useCallback(
		(isCreateSuccess) => {
			if (!isCreateSuccess) {
				void goToPage('default');
			}
		},
		[goToPage],
	);
	const isOpen = currentPage === 'edit_issue';

	const onFailure = useCallback(
		(error: Error | unknown) => {
			logSingleIssueCreateFailure(error);
		},
		[logSingleIssueCreateFailure],
	);

	const onSuccess = useCallback(
		(payload: IssueCreateEmbedSuccessPayload) => {
			logSingleIssueCreateSuccess();
			logCreateSingleIssueClick(payload?.issueId?.toString());
			triggerSingleIssueCreatedFlag(payload);

			// Save the cloud id in localStorage if an issue is created
			saveCloudIdToLocalStorage(payload);

			const response = mapGICPayloadToCreateIssueResponse(payload);
			const issueCreated = { ...response, summary: response.summary };
			void setSingleIssuesCreated(issueCreated);

			void setNextIssueToEdit();
		},
		[
			triggerSingleIssueCreatedFlag,
			logSingleIssueCreateSuccess,
			setSingleIssuesCreated,
			setNextIssueToEdit,
			logCreateSingleIssueClick,
		],
	);

	if (!currentIssue || !currentJiraProject || !issueType || !currentJiraSite) {
		// TODO return error screen component
		return null;
	}
	const { summary, description, id: issueId } = currentIssue;

	return (
		<SidePanelWrapper isOpen={isOpen}>
			<Stack testId="bulk-edit-content-wrapper" xcss={bulkEditContentWrapper}>
				<Box padding="space.200">
					<BulkCreateNavigationHeader onClose={onDismiss}>
						<FormattedMessage {...messages.bulkCreateEditHeaderText} />
					</BulkCreateNavigationHeader>
				</Box>
				<GicPanelBody
					key={`issue-id-${issueId}`}
					cloudId={currentJiraSite.cloudId}
					onSuccess={onSuccess}
					onFailure={onFailure}
					onClose={onClose}
					analyticsSource={getSource(isCreatedFromTable)}
					summary={summary}
					descriptionAdf={description}
					issueTypeId={issueType.id}
					configuration={{ disableProjectDropdown: true, displaySitePicker: false }}
					projectId={currentJiraProject.id}
					isCreatedWithAi={isCreatedWithAi}
					isCreatedFromTable={isCreatedFromTable}
				/>
				{isAiIssue && <AiSubFooter />}
			</Stack>
		</SidePanelWrapper>
	);
};
